<template>
  <section class="section">
    <nav class="level is-vcentered">
        <div class="level-left">
          <div class="level-item">
            <img class="level-item-img" src="@/assets/icon/servicios-level.png" alt="Servicios">
            <h1 class="title">Nuevo Servicio</h1>
          </div>
        </div>
      </nav>
      <main class="inner-section">
        <div class="container">
          <div class="columns is-multiline">
            <div class="column is-12">
              <a class="link_back is-inline-flex" @click="$router.go(-1)">
                <span class="arrow_back material-icons">
                  arrow_back
                </span>
                <p>
                  Regresar
                </p>
              </a>
            </div>
            <div class="column is-12">
              <div class="box">
                <div class="columns is-multiline">
                  <div class="column is-3">
                    <label class="label">Servicio</label>
                    <div class="field">
                      <div class="control">
                        <v-select
                        class="input_select"
                        @input="setServiceForm"
                          :class="{ 'is-danger': vverrors.has('service') }"
                          label="name"
                          placeholder='Servicio'
                          v-model="serviceId"
                          name="service"
                          :reduce="service => service.id"
                          :options="servicesList"
                          v-validate="'required'"
                        >
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="column is-3">
                    <label class="label">Proveedor</label>
                    <div class="field">
                      <div class="control">
                        <v-select
                          :disabled="!serviceId"
                          @input="setPartner"
                          class="input_select"
                          :class="{ 'is-danger': vverrors.has('service') }"
                          label="name"
                          placeholder='Proveedor'
                          v-model.number="supplierId"
                          name="service"
                          :reduce="supplier => supplier.id"
                          :options="supplierList"
                          v-validate="'required'"
                        >
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
                <base-loading v-if="loading" />
                <not-services v-if="!serviceId" />
                <loading-inspection
                  v-if="serviceId === LOADING_INSPECTION && !loading"
                  :supplierData="supplierData"
                  :myCustomerDetail="myCustomerDetail"
                  :sendRequestService="sendRequestService"
                />
                <quality-control
                  v-if="serviceId === QUALITY_CONTROL && !loading"
                  :supplierData="supplierData"
                  :myCustomerDetail="myCustomerDetail"
                  :sendRequestService="sendRequestService"
                />
                <supplier-audit
                  v-if="serviceId === SUPPLIER_AUDIT && !loading"
                  :supplierData="supplierData"
                  :myCustomerDetail="myCustomerDetail"
                  :sendRequestService="sendRequestService"
                />

                <!-- <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="control">
                      <button class="button button_ibs is-rounded sm_btn is-pulled-right">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </main>
  </section>
</template>

<script>
import service from '@/constants/catalogs/services'
import LoadingInspection from './ServicesForms/LoadingInspection.vue'
import SupplierAudit from './ServicesForms/SupplierAudit.vue'
import QualityControl from './ServicesForms/QualityControl.vue'
import NotServices from '../../components/NotServices.vue'
import BaseLoading from '../../components/ui/Loading.vue'
import { mapActions } from 'vuex'
export default {
  name: 'NewService',
  components: {
    LoadingInspection,
    SupplierAudit,
    QualityControl,
    NotServices,
    BaseLoading
  },
  data () {
    return {
      LOADING_INSPECTION: 1,
      QUALITY_CONTROL: 2,
      SUPPLIER_AUDIT: 4,
      servicesList: [],
      supplierList: [],
      serviceId: null,
      supplierId: null,
      supplierData: {},
      myCustomerDetail: {},
      loading: false
    }
  },
  methods: {
    ...mapActions(['getSuppliersList', 'getSupplierCustomerById', 'getMyCustomerDetail', 'saveRequestService']),
    setServiceForm (service) {
      this.supplierId = null
    },
    async setPartner () {
      this.loading = true
      const resp = await this.getSupplierCustomerById(this.supplierId)
      if (resp.success) {
        this.supplierData = resp.supplier
      }
      this.loading = false
    },
    async sendRequestService (data) {
      const isSaved = await this.saveRequestService({ serviceId: this.serviceId, supplierId: this.supplierId, ...data })
      if (isSaved) {
        this.serviceId = null
        this.supplierId = null
      }
    }
  },
  beforeMount () {
    this.servicesList = service.filter(service => service.id !== 3)
    this.getSuppliersList()
      .then(response => {
        if (response.success) {
          this.supplierList = response.suppliers
        }
      })
    this.getMyCustomerDetail()
      .then(response => {
        if (response.success) {
          this.myCustomerDetail = response.detail
        }
      })
  }
}
</script>

<style scoped>
  .section {
      width: 100%;
      min-height: calc(100vh - 90px);
      padding: 0;
  }
</style>
